<template>
  <div class="register">
    <h2>注册</h2>
    <div>
      <label for="username">账号:</label>
      <input v-model="username" id="username" type="text" />
    </div>
    <div>
      <label for="password">密码:</label>
      <input v-model="password" id="password" type="password" />
    </div>
    <div>
      <label for="name">名称:</label>
      <input v-model="name" id="name" type="text" />
    </div>
    <div>
      <label for="region">地区:</label>
      <select v-model="region" id="region">
        <option v-for="r in regions" :key="r" :value="r">{{ r }}</option>
      </select>
    </div>
    <button @click="register">注册</button>
    <div v-if="message">{{ message }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      username: '',
      password: '',
      name: '',
      region: '',
      regions: ['北美', '南美', '欧洲', '亚洲', '非洲', '澳洲', '南极洲'],
      message: ''
    };
  },
  methods: {
    register() {
      // 在实际应用中，你会向后端API发送请求来完成注册操作。
      if(this.username && this.password && this.name && this.region) {
        this.message = "注册成功!";
      } else {
        this.message = "请填写所有字段!";
      }
    }
  }
};
</script>

<style scoped>
.register {
  max-width: 300px;
  margin: 40px auto;
  padding: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}
label {
  display: block;
  margin-bottom: 8px;
}
input, select, button {
  width: 100%;
  padding: 8px;
  margin-bottom: 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
}
</style>

